<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api'

import { getUserFrontendActionForNotification } from '@/features/notifications/notifications.api'
import router from '@/infrastructure/router'
import store from '@/infrastructure/store'

export default defineComponent({
  props: {
    notificationId: { type: String, required: true },
  },

  setup(props) {
    const isLoading = ref(false)
    const redirectLocation = ref(null)
    const error = ref(null)

    const getRouterLocationFromFrontendAction = (frontendAction) => {
      const targetPage = frontendAction ? frontendAction['TargetPage'] : undefined

      if (!frontendAction || !targetPage) {
        console.log(`targetPage is undefined for notificationId ${props.notificationId}, navigating to home page`)
        return { name: 'home' }
      }

      switch (targetPage) {
        case 'HomePage':
          return { name: 'home' }

        case 'NotificationsListPage':
          return { name: 'notifications' }

        case 'UserPreferencesPage':
          return { name: 'preferences' }

        case 'AdminFeedbackPage':
          return { name: 'admin-feedback' }

        case 'FeedbackPage':
          return { name: 'feedback' }

        case 'FriendsListPage':
          return { name: 'friends' }

        case 'MyItemsListPage':
          return { name: 'my-items' }

        case 'FriendWithItemsPage': {
          const specificFriendItemId = frontendAction['SpecificFriendItemId']
          const query = specificFriendItemId ? { highlightedItemId: specificFriendItemId } : {}
          return { name: 'friend-with-items', params: { userId: frontendAction['FriendUserId'] }, query: query }
        }

        case 'FriendWithArchivedItemsPage': {
          const specificFriendItemId = frontendAction['SpecificFriendItemId']
          const query = specificFriendItemId ? { highlightedItemId: specificFriendItemId } : {}
          return {
            name: 'friend-with-archived-items',
            params: { userId: frontendAction['FriendUserId'] },
            query: query,
          }
        }

        default: {
          console.warn(`Unsupported target page to redirect from user push notification ${props.notificationId}, navigating to home page`)
          return { name: 'notifications' }
        }
      }
    }

    onMounted(async () => {
      await store.dispatch('notifications/markNotificationRead', { notificationId: props.notificationId })
    })
    onMounted(async () => {
      isLoading.value = true
      try {
        const frontendAction = await getUserFrontendActionForNotification({ notificationId: props.notificationId })
        // console.debug('frontendAction', frontendAction)

        redirectLocation.value = getRouterLocationFromFrontendAction(frontendAction)

        const delay = 1000
        console.debug(`Navigating to routerLocation in ${delay}ms, location:`, JSON.stringify(redirectLocation.value))

        // console.error(`TODO: uncomment redirect after delay ${delay}ms`, JSON.stringify(redirectLocation.value), router)
        setTimeout(async () => {
          if (redirectLocation.value) {
            await router.push(redirectLocation.value)
          }
        }, delay)
      } catch (err) {
        error.value = err
      } finally {
        isLoading.value = false
      }
    })

    return {
      isLoading,
      redirectLocation,
      error,
    }
  },
})
</script>

<template>
  <div>
    <v-card v-if="isLoading || redirectLocation" loading>
      <v-card-text>Please wait while we load the notification details...</v-card-text>
    </v-card>
    <v-alert v-else type="error"> Unable to load error details, error: {{ error }}</v-alert>
  </div>
</template>
